import { Button, Form } from "antd";
export const StepButton = ({
  onStepChange,
  currentStep,
  flowStepsLenght,
}) => {
  return (
    <div className="w-[60vw] fixed bottom-[3vh]  z-50">
      <Form.Item>
        <div className="w-full flex flex-row justify-between">
          <Button
            className="bg-blue-500 rounded-md py-0 px-6 disabled:bg-blue-300"
            onClick={() => {
              onStepChange(currentStep - 1);
            }}
            disabled={currentStep == 0}
            type="primary"
            htmlType="submit"
          >
            Quay lại
          </Button>
          <Button
            className="bg-blue-500 rounded-md py-0 px-6 disabled:bg-blue-300"
            onClick={() => {
              onStepChange(currentStep + 1);
            }}
            // disabled={currentStep == flowStepsLenght - 1}
            type="primary"
            htmlType="submit"
          >
            {currentStep == flowStepsLenght - 1 ? "Gửi kết quả" : "Tiếp tục"}
          </Button>
        </div>
      </Form.Item>
    </div>
  );
};
