export const CURRENT_DATA = {
  "Đi bộ": [20,undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined],
  "Xe đạp": [6,undefined,2,undefined,undefined,2000,undefined,undefined,undefined,undefined],
  "Phương tiện công cộng": [20,undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined],
  "Xe máy cá nhân": [3,undefined,2,undefined,2000,5000,undefined,undefined,undefined,undefined],
  "Xe ôm": [3,"Có",undefined,"Có",15000,undefined,undefined,undefined,undefined,undefined],
  "Ô tô cá nhân": [3,undefined,2,undefined,10000,30000,undefined,undefined,undefined,undefined],
  "Taxi": [3,"Có",2,"Có/Không",35000,undefined,undefined,undefined,"Chiếm dụng cả xe","Có"],
  "Dịch vụ gọi xe (Xe máy)": [3,"Có",undefined,"Có/Không",15000,undefined,undefined,undefined,undefined,"Có"],
  "Dịch vụ gọi xe (Ô tô)": [3,"Có",undefined,"Có/Không",25000,undefined,undefined,undefined,"Gộp khách/Chiếm dụng cả xe","Có"],
  "Khác": [undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined],
};

export const SAV_DATA = [
    [10,1,"","",15000,"",5,"Thấp","Gộp khách","Có"],
    [10,1,"","Có",20000,"",4,"Cao","Gộp khách","Có"],
    [10,3,"","Có",20000,"",5,"Thấp","Gộp khách","Có"],
    [6,3,"","",20000,"",4,"Cao","Gộp khách","Có"],
    [8,1,"","",10000,"",5,"Cao","Gộp khách","Có"],
    [8,2,"","Có",20000,"",5,"Thấp","Gộp khách","Có"],
    [8,3,"","Có",15000,"",3,"Cao","Gộp khách","Có"],
    [8,1,"","",20000,"",3,"Cao","Gộp khách","Có"],
    [10,2,"","",10000,"",4,"Cao","Gộp khách","Có"],
    [10,3,"","Có",10000,"",3,"Cao","Gộp khách","Có"],
    [10,3,"","Có",10000,"",3,"Thấp","Gộp khách","Có"],
    [10,1,"","Có",20000,"",4,"Cao","Gộp khách","Có"],
    [8,2,"","Có",20000,"",5,"Cao","Gộp khách","Có"],
    [6,1,"","Có",10000,"",3,"Thấp","Gộp khách","Có"],
    [10,3,"","Có",20000,"",5,"Thấp","Gộp khách","Có"],
    [6,2,"","Có",10000,"",5,"Cao","Gộp khách","Có"],
    [6,3,"","",15000,"",5,"Thấp","Gộp khách","Có"],
    [6,2,"","",20000,"",3,"Thấp","Gộp khách","Có"],
    [6,3,"","",20000,"",4,"Cao","Gộp khách","Có"],
    [6,1,"","Có",15000,"",4,"Thấp","Gộp khách","Có"],
    [8,3,"","",10000,"",4,"Thấp","Gộp khách","Có"],
    [10,2,"","",15000,"",3,"Cao","Gộp khách","Có"],
    [8,1,"","",20000,"",3,"Thấp","Gộp khách","Có"],
    [6,1,"","Có",10000,"",3,"Thấp","Gộp khách","Có"],
    [10,1,"","",15000,"",5,"Thấp","Gộp khách","Có"],
    [8,1,"","",10000,"",5,"Cao","Gộp khách","Có"],
    [8,3,"","Có",15000,"",3,"Cao","Gộp khách","Có"],
    [8,3,"","",10000,"",4,"Thấp","Gộp khách","Có"],
    [6,1,"","Có",15000,"",4,"Cao","Gộp khách","Có"],
    [8,2,"","Có",15000,"",4,"Thấp","Gộp khách","Có"],
    [10,2,"","",10000,"",4,"Thấp","Gộp khách","Có"],
    [6,2,"","",20000,"",3,"Thấp","Gộp khách","Có"],
    [10,2,"","",15000,"",3,"Cao","Gộp khách","Có"],
    [6,3,"","",15000,"",5,"Cao","Gộp khách","Có"],
    [8,2,"","Có",15000,"",4,"Thấp","Gộp khách","Có"],
    [6,2,"","Có",10000,"",5,"Cao","Gộp khách","Có"],
]

export const AV_DATA = [
    [4,4,"","Có",40000,"",3,"","Chiếm dụng cả xe","Có"],
    [8,4,"","Có",20000,"",4,"","Chiếm dụng cả xe","Có"],
    [4,3,"","",30000,"",4,"","Chiếm dụng cả xe","Có"],
    [6,3,"","",20000,"",5,"","Chiếm dụng cả xe","Có"],
    [6,5,"","",20000,"",4,"","Chiếm dụng cả xe","Có"],
    [6,4,"","",40000,"",5,"","Chiếm dụng cả xe","Có"],
    [4,4,"","",20000,"",5,"","Chiếm dụng cả xe","Có"],
    [4,5,"","Có",30000,"",5,"","Chiếm dụng cả xe","Có"],
    [6,3,"","Có",40000,"",3,"","Chiếm dụng cả xe","Có"],
    [6,4,"","Có",30000,"",4,"","Chiếm dụng cả xe","Có"],
    [8,5,"","",20000,"",3,"","Chiếm dụng cả xe","Có"],
    [6,5,"","",30000,"",3,"","Chiếm dụng cả xe","Có"],
    [4,3,"","Có",20000,"",3,"","Chiếm dụng cả xe","Có"],
    [4,3,"","Có",20000,"",3,"","Chiếm dụng cả xe","Có"],
    [8,5,"","Có",40000,"",5,"","Chiếm dụng cả xe","Có"],
    [8,3,"","",40000,"",4,"","Chiếm dụng cả xe","Có"],
    [6,5,"","",30000,"",3,"","Chiếm dụng cả xe","Có"],
    [6,5,"","Có",20000,"",4,"","Chiếm dụng cả xe","Có"],
    [4,4,"","",40000,"",3,"","Chiếm dụng cả xe","Có"],
    [4,3,"","",30000,"",4,"","Chiếm dụng cả xe","Có"],
    [4,5,"","Có",40000,"",4,"","Chiếm dụng cả xe","Có"],
    [8,3,"","",30000,"",5,"","Chiếm dụng cả xe","Có"],
    [8,3,"","",40000,"",4,"","Chiếm dụng cả xe","Có"],
    [6,4,"","",40000,"",5,"","Chiếm dụng cả xe","Có"],
    [6,3,"","Có",20000,"",5,"","Chiếm dụng cả xe","Có"],
    [8,4,"","",30000,"",3,"","Chiếm dụng cả xe","Có"],
    [6,3,"","Có",40000,"",3,"","Chiếm dụng cả xe","Có"],
    [8,3,"","Có",30000,"",5,"","Chiếm dụng cả xe","Có"],
    [8,5,"","Có",40000,"",5,"","Chiếm dụng cả xe","Có"],
    [8,5,"","",20000,"",3,"","Chiếm dụng cả xe","Có"],
    [4,4,"","",20000,"",5,"","Chiếm dụng cả xe","Có"],
    [8,4,"","Có",30000,"",3,"","Chiếm dụng cả xe","Có"],
    [4,5,"","",40000,"",4,"","Chiếm dụng cả xe","Có"],
    [8,4,"","Có",20000,"",4,"","Chiếm dụng cả xe","Có"],
    [6,4,"","Có",30000,"",4,"","Chiếm dụng cả xe","Có"],
    [4,5,"","Có",30000,"",5,"","Chiếm dụng cả xe","Có"],
]