import { useEffect, useState } from "react";
import {
  Title,
  CheckboxAnswerQuestion,
  ScoredAnswerQuestion,
  RadioGroupAnswerQuestion,
} from "./base";
import { StepButton } from "./step-button";
import { Form } from "antd";
export const Section1 = ({
  title,
  handleSubmit,
  handleFail,
  currentStep,
  flowStepsLenght,
}) => {
  const majorIndex = 1;

  const [nextStep, setNextStep] = useState();

  return (
    <div className="text-left w-full">
      <Title index={majorIndex} content={title} />
      <p>
        Xe tự hành là phương tiện trong đó ít nhất một số khía cạnh của hệ thống
        kiểm soát quan trọng về an toàn (chẳng hạn như hệ thống lái, ga hoặc
        phanh) hoạt động mà không cần người lái trực tiếp điều khiển. Các phương
        tiện cung cấp cảnh báo an toàn cho người lái xe nhưng không kiểm soát
        phương tiện không được coi là tự lái. Xe tự hành có thể sử dụng cảm
        biến, máy ảnh, GPS và viễn thông trên xe để thu thập thông tin nhằm đưa
        ra quyết định liên quan đến các tình huống nguy cấp về an toàn và hành
        động phù hợp bằng cách kiểm soát phương tiện ở một mức độ nào đó. Ví dụ
        về các phương tiện tự lái bao gồm từ những phương tiện đảm nhận các chức
        năng cơ bản như kiểm soát hành trình, đến các phương tiện tự lái hoàn
        toàn mà không cần người điều khiển.
      </p>
      <Form onFinish={(value)=>handleSubmit(value, nextStep)} onFinishFailed={handleFail}>
        <RadioGroupAnswerQuestion
          majorIndex={majorIndex}
          mirrorIndex={1}
          question={"Anh/Chị đã từng nghe nói về xe tự hành chưa?"}
          answers={[
            "Tôi chưa từng nghe đến",
            "Một vài người đã nói với tôi",
            "Tôi nghĩ rằng tôi đã được nghe nói đến",
            "Tôi đã đọc thông tin về nó hơn một lần nhưng không thường xuyên",
            "Tôi đã đọc thông tin về nó một lần",
            "Tôi đang theo dõi tin tức về nó một cách thường xuyên",
          ]}
          showType="column"
        />

        <ScoredAnswerQuestion
          majorIndex={majorIndex}
          mirrorIndex={2}
          question={"Ý định đi xe tự hành"}
          rawQuestion={"Ý định đi xe tự hành"}
          answers={[
            "Tôi dự định sẽ đi xe tự hành khi xe tự hành sẵn có",
            "Tôi dự định sẽ đi xe tự hành trong tương lai gần",
            "Tôi dự định sẽ thường xuyên đi xe tự hành trong tương lai gần",
            "Tôi dự định sẽ thỉnh thoảng đi xe tự hành trong tương lai gần",
            "Tôi sẽ giới thiệu việc sử dụng xe tự hành đến người khác",
          ]}
        />
        <StepButton
          onStepChange={setNextStep}
          currentStep={currentStep}
          flowStepsLenght={flowStepsLenght}
        />
      </Form>
    </div>
  );
};
