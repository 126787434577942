export const Introduce = () => {
  return (
    <div>
      <img
        src={require("../assets/images/hiroshima.png")}
        width="100%"
        height={"100%"}
        className="rounded-lg"
      ></img>
      <div className="text-center my-6">
        <p className="text-4xl font-semibold text-green-600">PHIẾU KHẢO SÁT</p>
        <p className="text-2xl font-semibold">
          Đánh giá sự chấp nhận của công chúng và tác động đến hành vi đi lại
          của xe tự lái ở thành phố đang phát triển
        </p>
        <p className="text-2xl font-semibold">
          Bằng chứng từ một thực nghiệm tại Hà Nội - Việt Nam
        </p>
      </div>

      <div className="text-left">
        <p>
          Lời đầu tiên, chúng tôi xin gửi tới Anh/Chị lời chào trân trọng nhất!
        </p>
        <p>Tôi là Phạm Sơn, nghiên cứu sinh tại Đại học Hiroshima, Nhật Bản.</p>
        <p>
          Chúng tôi đang tiến hành nghiên cứu về{" "}
          <span className="font-semibold">
            “Sự chấp nhận của công chúng và tác động đến hành vi đi lại của xe
            tự lái ở thành phố đang phát triển”.
          </span>{" "}
          Mục đích của nghiên cứu nhằm tìm ra tiềm năng của các xu hướng di
          chuyển mới tại Việt Nam và xác định các yếu tố, đặc điểm hành vi sẽ
          ảnh hưởng đến ý định sử dụng xe tự lái. Tất cả thông tin thu được
          trong cuộc khảo sát này chỉ được sử dụng cho mục đích nghiên cứu và
          tính ẩn danh của người trả lời được đảm bảo tuyệt đối.
        </p>
        <p>
          Khảo sát này được thực hiện bởi Phạm Sơn, Trường Cao học Khoa học và
          Kỹ thuật,{" "}
          <span className="font-semibold">Đại học Hiroshima, Nhật Bản,</span>{" "}
          với sự hợp tác của{" "}
          <span className="font-semibold">Đại học Phenikaa, Việt Nam.</span>
        </p>
        <p>
          Chúng tôi mong nhận được sự hỗ trợ của Anh/Chị bằng cách trả lời các
          câu hỏi sau. Tất cả các ý kiến của Anh/Chị sẽ được sử dụng cho nghiên
          cứu này. Sự hợp tác của Anh/Chị là một đóng góp quan trọng cho sự
          thành công trong nghiên cứu của chúng tôi.
        </p>
      </div>

      <div className="mt-6 rounded-lg border-2 border-green-600">
        <p>
          NCS: <span className="font-semibold">Phạm Sơn</span>
        </p>
        <p>Giáo sư hướng dẫn: Fujiwara Akimasa</p>
        <p>Điện thoại: (+81) 090 9229 9995 / (+84) 038 678 5157</p>
        <p>Email: phamvanson@hiroshima-u.ac.jp</p>
        <p>Trường Cao học Khoa học Tiên tiến và Kỹ thuật </p>
        <p>Đại học Hiroshima, Nhật Bản</p>
      </div>
    </div>
  );
};
