import { useState } from "react";
import {
  Title,
  BaseQuestion,
  YesOrNoQuestion,
  InputNumberQuestion,
  InputQuestion,
  ScoredAnswerQuestion,
  CheckboxAnswerQuestion,
  RadioGroupAnswerQuestion,
  CheckBoxGroupAnswerQuestion,
} from "./base";
import { Form } from "antd";
import { StepButton } from "./step-button";
export const Section5 = ({
  title,
  handleSubmit,
  handleFail,
  currentStep,
  flowStepsLenght,
}) => {
  const majorIndex = 5;
  const [nextStep, setNextStep] = useState();

  return (
    <div className="text-left">
      <Title index={majorIndex} content={title} />
      <p className="mb-6">
        (Anh chị vui lòng điền thông tin đầy đủ, nhóm nghiên cứu sẽ gửi tặng
        phần quà theo thông tin Anh chị cung cấp dưới đây.)
      </p>

      <Form
        onFinish={(value) => handleSubmit(value, nextStep)}
        onFinishFailed={handleFail}
      >
        {/* question 1 */}
        <div>
          <BaseQuestion
            majorIndex={majorIndex}
            mirrorIndex={1}
            question={"Tên/Địa chỉ"}
          />
          <InputQuestion
            majorIndex={undefined}
            mirrorIndex={undefined}
            question="Họ và tên"
            rawQuestion="Họ và tên"
          />
          <InputQuestion
            majorIndex={undefined}
            mirrorIndex={undefined}
            question="Số tài khoản"
            rawQuestion="Số tài khoản"
          />
          <InputQuestion
            majorIndex={undefined}
            mirrorIndex={undefined}
            question="Tên ngân hàng"
            rawQuestion="Tên ngân hàng"
          />
          <InputQuestion
            majorIndex={undefined}
            mirrorIndex={undefined}
            question="Điện thoại"
            rawQuestion="Điện thoại"
          />
          <InputQuestion
            majorIndex={undefined}
            mirrorIndex={undefined}
            question="Địa chỉ"
            rawQuestion="Địa chỉ"
          />
          <InputQuestion
            majorIndex={undefined}
            mirrorIndex={undefined}
            question="Email"
            rawQuestion="Email"
          />
          <InputQuestion
            majorIndex={undefined}
            mirrorIndex={undefined}
            question="Quận/Huyện Tỉnh/TP"
            rawQuestion="Quận/Huyện Tỉnh/TP"
          />
        </div>

        {/* question 2 */}
        <div>
          <BaseQuestion
            majorIndex={majorIndex}
            mirrorIndex={2}
            question={"Thông tin chung"}
          />
          <InputNumberQuestion
            majorIndex={undefined}
            mirrorIndex={undefined}
            question="Tuổi"
            rawQuestion="Tuổi"
            unit={"tuổi"}
            min={0}
            max={100}
          />
          <RadioGroupAnswerQuestion
            majorIndex={undefined}
            mirrorIndex={undefined}
            question={"Giới tính"}
            answers={["Nam", "Nữ", "Khác"]}
          />
          <RadioGroupAnswerQuestion
            majorIndex={undefined}
            mirrorIndex={undefined}
            question={"Tình trạng cư trú"}
            answers={["Thường trú", "Tạm trú", "Không đăng ký"]}
          />
        </div>

        {/* question 3 */}
        <RadioGroupAnswerQuestion
          majorIndex={majorIndex}
          mirrorIndex={3}
          question={"Anh/Chị có bằng lái xe ô tô hay xe máy không?"}
          answers={["Xe máy", "Ô tô", "Cả hai", "Không"]}
        />

        {/* question 4 */}
        <CheckBoxGroupAnswerQuestion
          majorIndex={majorIndex}
          mirrorIndex={4}
          question={"Anh/Chị có đang sở hữu phương tiện nào không?"}
          answers={["Ô tô", "Xe máy", "Xe đạp", "Không"]}
          // answers={["Ô tô", "Xe máy", "Xe đạp"]}
          showType="column"
        />

        {/* question 5 */}
        <RadioGroupAnswerQuestion
          majorIndex={majorIndex}
          mirrorIndex={5}
          question={"Thu nhập hàng tháng (VND/tháng)"}
          showType={"table"}
          answers={[
            "Không có thu nhập",
            "Dưới 1,000,000",
            "1,000,001 – 3,000,000",
            "3,000,001 – 5,000,000",
            "5,000,001 – 8,000,000",
            "8,000,001 – 10,000,000",
            "10,000,001 – 12,000,000",
            "12,000,0001 – 15,000,000",
            "15,000,001 – 20,000,000",
            "20,000,001 – 30,000,000",
            "30,000,001 – 50,000,000",
            "50,000,001 – 80,000,000",
            "80,000,001 – 100,000,000",
            "100,000,001 – 200,000,000",
            "Trên 200,000,000",
          ]}
        />

        {/* question 6 */}
        <RadioGroupAnswerQuestion
          majorIndex={majorIndex}
          mirrorIndex={6}
          question={"Nghề nghiệp"}
          answers={[
            "Sinh viên",
            "Nhân viên tại các doanh nghiệp tư nhân",
            "Công chức/viên chức tại các cơ quan nhà nước",
            "Tự do",
            "Nhân viên bán thời gian",
            "Đã nghỉ hưu/đang tìm kiếm việc làm",
          ]}
          showType={"column"}
        />

        {/* question 7 */}
        <div>
          <BaseQuestion
            majorIndex={majorIndex}
            mirrorIndex={3}
            question={"Hộ gia đình Anh/Chị đang sở hữu bao nhiêu phương tiện?"}
          />
          <InputNumberQuestion
            majorIndex={undefined}
            mirrorIndex={undefined}
            question=""
            rawQuestion={
              "Hộ gia đình Anh/Chị đang sở hữu bao nhiêu phương tiện? ô tô"
            }
            unit={"ô tô"}
            min={0}
            max={100}
          />
          <InputNumberQuestion
            majorIndex={undefined}
            mirrorIndex={undefined}
            question=""
            rawQuestion={
              "Hộ gia đình Anh/Chị đang sở hữu bao nhiêu phương tiện? xe máy"
            }
            unit={"xe máy"}
            min={0}
            max={100}
          />
        </div>
        <StepButton
          onStepChange={setNextStep}
          currentStep={currentStep}
          flowStepsLenght={flowStepsLenght}
        />
      </Form>
    </div>
  );
};
