import { useState } from "react";
import {
  Title,
  BaseQuestion,
  YesOrNoQuestion,
  InputNumberQuestion,
  InputQuestion,
  ScoredAnswerQuestion,
  CheckboxAnswerQuestion,
  RadioGroupAnswerQuestion,
  TableQuestion,
} from "./base";
import { StepButton } from "./step-button";
import { Form } from "antd";
const stringVehicles = [
  "Đi bộ",
  "Xe đạp",
  `Phương tiện công cộng`,
  `Xe máy cá nhân`,
  "Xe ôm",
  `Ô tô cá nhân`,
  "Taxi",
  `Dịch vụ gọi xe (Xe máy)`,
  `Dịch vụ gọi xe (Ô tô)`,
  "Khác",
];
const vehicles = [
  "Đi bộ",
  "Xe đạp",
  <span>
    Phương tiện
    <br />
    công cộng
  </span>,
  <span>
    Xe máy
    <br />
    cá nhân
  </span>,
  "Xe ôm",
  <span>
    Ô tô
    <br />
    cá nhân
  </span>,
  "Taxi",
  <span>
    Dịch vụ gọi xe
    <br />
    (Xe máy)
  </span>,
  <span>
    Dịch vụ gọi xe
    <br />
    (Ô tô)
  </span>,
  "Khác",
];
export const Section3 = ({
  title,
  handleSubmit,
  handleFail,
  currentStep,
  flowStepsLenght,
}) => {
  const majorIndex = 3;
  const [nextStep, setNextStep] = useState();

  return (
    <div className="text-left">
      <Title index={majorIndex} content={title} />

      <Form
        onFinish={(value) => handleSubmit(value, nextStep)}
        onFinishFailed={handleFail}
      >
        <InputNumberQuestion
          majorIndex={majorIndex}
          mirrorIndex={1}
          question="Khoảng cách từ Nhà đến nơi làm việc/trường học:"
          rawQuestion="Khoảng cách từ Nhà đến nơi làm việc/trường học:"
          unit={"km"}
        />
        <RadioGroupAnswerQuestion
          majorIndex={majorIndex}
          mirrorIndex={2}
          question={"Phương tiện chính sử dụng Anh/Chị sử dụng hàng ngày"}
          answers={stringVehicles.map((vehicle) => vehicle)}
        />
        {/* question 3 */}
        <TableQuestion
          majorIndex={majorIndex}
          mirrorIndex={3}
          question="Phương tiện nào sau đây là phương tiện chính của Anh/Chị cho mỗi mục đích chuyến đi"
          rawQuestion="Phương tiện nào sau đây là phương tiện chính của Anh/Chị cho mỗi mục đích chuyến đi"
          rows={[
            ["Nơi làm việc / Trường học", "Nơi làm việc / Trường học"],
            ["Mua sắm", "Mua sắm"],
            ["Đi công tác", "Đi công tác"],
            ["Giải trí", "Giải trí"],
            ["Chuyến đi khác", "Chuyến đi khác"],
          ]}
          columns={vehicles}
          rawColumns={stringVehicles}
          firstColName="Mục đích chuyến đi"
          colnumber={"12"}
        />
        {/* question 4 */}
        <YesOrNoQuestion
          majorIndex={majorIndex}
          mirrorIndex={4}
          question="Anh/Chị có tài khoản dịch vụ gọi xe không? (Uber, Grab, Bee, etc.)"
          rawQuestion="Anh/Chị có tài khoản dịch vụ gọi xe không? (Uber, Grab, Bee, etc.)"
          yesAnswer="Có"
          noAnswer="Không"
        />
        {/* question 5 */}
        <RadioGroupAnswerQuestion
          majorIndex={majorIndex}
          mirrorIndex={5}
          question="Nếu có tài khoản dịch vụ gọi xe, Anh/Chị đã sử dụng khoảng bao nhiêu lần trong tháng trước?"
          answers={["0", "1-3", "4-5", "6-10", ">10"]}
        />

        {/* question 6 */}
        <InputNumberQuestion
          majorIndex={majorIndex}
          mirrorIndex={6}
          question="Anh/Chị đã sử dụng điện thoại thông minh khoảng bao nhiêu năm rồi?"
          rawQuestion="Anh/Chị đã sử dụng điện thoại thông minh khoảng bao nhiêu năm rồi?"
          unit={"năm"}
          min={0}
          max={100}
        />
        <StepButton
          onStepChange={setNextStep}
          currentStep={currentStep}
          flowStepsLenght={flowStepsLenght}
        />
      </Form>
    </div>
  );
};
