/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "apiservey",
            "endpoint": "https://urijhy61y0.execute-api.ap-northeast-1.amazonaws.com/staging",
            "region": "ap-northeast-1"
        },
        {
            "name": "survey",
            "endpoint": "https://n1scvvjsr2.execute-api.ap-northeast-1.amazonaws.com/staging",
            "region": "ap-northeast-1"
        },
        {
            "name": "api0302",
            "endpoint": "https://qw1pqps48j.execute-api.ap-northeast-1.amazonaws.com/staging",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://qjjkc2kgwbaqlkxu54czk5x46a.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-5r4qoh7dlvfmbfh4ajv6wibpqy",
    "aws_cognito_identity_pool_id": "ap-northeast-1:6f81dd3d-2760-4495-be63-f23d212d2895",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_JMOaVAD8K",
    "aws_user_pools_web_client_id": "gh9uemb2aql6tvl53bue13hl6",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "ap-northeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "serveys-staging",
            "region": "ap-northeast-1"
        }
    ]
};


export default awsmobile;
