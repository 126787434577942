import { useEffect, useState } from "react";
import {
  Title,
  BaseQuestion,
  YesOrNoQuestion,
  InputNumberQuestion,
  InputQuestion,
  ScoredAnswerQuestion,
  TableQuestion,
} from "./base";
import { Form } from "antd";
import { StepButton } from "./step-button";

export const Section2 = ({
  title,
  handleSubmit,
  handleFail,
  currentStep,
  flowStepsLenght,
}) => {
  const majorIndex = 2;
  const [nextStep, setNextStep] = useState();
  const [isUsedMRT, setUsedMRT] = useState(false);

  const renderTable = () => {
    return (
      <>
        <p className="text-right italic">Đơn vị: VND</p>
        <table className="table-auto border-collapse w-full">
          <thead>
            <tr>
              <th className="border-t-2 border-solid border-gray-500"></th>
              <th className="border-t-2 border-solid border-gray-500">
                Vé lượt
              </th>
              <th className="border-t-2 border-solid border-gray-500">
                Vé ngày (Nhiều lần)
              </th>
              <th className="border-t-2 border-solid border-gray-500">
                Vé tháng
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border-y-[1px] border-dashed border-gray-500">
                MRT
              </td>
              <td className="border-y-[1px] border-dashed border-gray-500">
                8,000 – 15,000
              </td>
              <td className="border-y-[1px] border-dashed border-gray-500">
                30,000
              </td>
              <td className="border-y-[1px] border-dashed border-gray-500">
                200,000
              </td>
            </tr>
            <tr>
              <td className="border-b-2  border-solid border-gray-500">BRT</td>
              <td className="border-b-2 border-solid border-gray-500">7,000</td>
              <td className="border-b-2 border-solid border-gray-500">
                55,000 - 100,000
              </td>
              <td className="border-b-2  border-solid border-gray-500"></td>
            </tr>
          </tbody>
        </table>
        <p className="text-center italic">
          Bảng 5.1: Giá vé hiện tại của hệ thống MRT/BRT
        </p>
      </>
    );
  };
  const renderDescription = () => {
    return (
      <div>
        <p className="font-semibold">
          Mass Rapid Transit (MRT): Hệ thống tàu đường sắt trên cao
        </p>
        <p className="font-semibold">
          Bus Rapid Transit (BRT): Hệ thống xe buýt nhanh
        </p>
        <p>
          Tuyến tàu đường sắt trên cao Cát Linh – Hà Đông và Tuyến buýt nhanh
          Yên Nghĩa – Kim Mã là hai tuyến giao thông công cộng thuận tiện, nhanh
          chóng đầu tiên và duy nhất hiện nay, đáp ứng nhu cầu thiết thực của
          người dân thủ đô Hà Nội. Sau khi đưa vào khai thác, chúng trở thành
          phương tiện đi lại hàng ngày của hàng vạn người dân Thủ đô, và lợi ích
          lớn nhất là đã dần hình thành một văn hóa giao thông mới.
        </p>
        {renderTable()}
      </div>
    );
  };
  return (
    <div className="text-left">
      <Title index={majorIndex} content={title} />
      {renderDescription()}
      <Form
        onFinish={(value) => handleSubmit(value, nextStep)}
        onFinishFailed={handleFail}
        onChange={(e) => {
          // console.debug("2:", e.target.value);
          if (e.target.value === "Đã từng") {
            setUsedMRT(true);
          } else if (e.target.value === "Chưa từng") {
            setUsedMRT(false);
          }
        }}
      >
        <YesOrNoQuestion
          majorIndex={majorIndex}
          mirrorIndex={1}
          question="Anh/Chị đã từng đi tàu đường sắt trên cao Cát Linh – Hà Đông hay tuyến xe buýt nhanh Yên Nghĩa – Kim Mã chưa?"
          rawQuestion="Anh/Chị đã từng đi tàu đường sắt trên cao Cát Linh – Hà Đông hay tuyến xe buýt nhanh Yên Nghĩa – Kim Mã chưa?"
        />

        {isUsedMRT && (
          <div>
            <InputNumberQuestion
              majorIndex={majorIndex}
              mirrorIndex={2}
              question="Khoảng cách từ nhà đến điểm dừng BRT/MRT gần nhất:"
              rawQuestion="Khoảng cách từ nhà đến điểm dừng BRT/MRT gần nhất:"
              unit={"km"}
            />
            {/* question 3 */}
            <BaseQuestion
              majorIndex={majorIndex}
              mirrorIndex={3}
              question="Điểm đầu – Điểm cuối của chuyến đi MRT/BRT"
            />
            <InputQuestion
              majorIndex={undefined}
              mirrorIndex={undefined}
              question="Điểm đầu (Ga MRT)"
              rawQuestion="Điểm đầu (Ga MRT)"
            />
            <InputQuestion
              majorIndex={undefined}
              mirrorIndex={undefined}
              question="Điểm cuối (Ga MRT)"
              rawQuestion="Điểm cuối (Ga MRT)"
            />
            <InputQuestion
              majorIndex={undefined}
              mirrorIndex={undefined}
              question="Điểm đầu (Ga BRT)"
              rawQuestion="Điểm đầu (Ga BRT)"
            />
            <InputQuestion
              majorIndex={undefined}
              mirrorIndex={undefined}
              question="Điểm cuối (Ga BRT)"
              rawQuestion="Điểm cuối (Ga BRT)"
            />

            {/* question 4 */}
            <BaseQuestion
              majorIndex={majorIndex}
              mirrorIndex={4}
              question="Thông tin chuyến đi"
            />
            <InputQuestion
              majorIndex={undefined}
              mirrorIndex={undefined}
              question="Thời gian khởi hành:"
              rawQuestion="Thời gian khởi hành:"
              placeholder={" (vd, 8:30)"}
            />
            <InputNumberQuestion
              majorIndex={undefined}
              mirrorIndex={undefined}
              question="Tổng thời gian đi:"
              rawQuestion="Tổng thời gian đi:"
              unit={"phút"}
            />
            <InputNumberQuestion
              majorIndex={undefined}
              mirrorIndex={undefined}
              question="Tổng quãng đường đi:"
              rawQuestion="Tổng quãng đường đi:"
              unit={"km"}
            />
            <InputNumberQuestion
              majorIndex={undefined}
              mirrorIndex={undefined}
              question="Tổng chi phí:"
              rawQuestion="Tổng chi phí:"
              unit="VND"
            />
          </div>
        )}

        {/* question 5 */}
        <TableQuestion
          majorIndex={majorIndex}
          mirrorIndex={isUsedMRT ? 5 : 2}
          question="Vui lòng cho biết mức độ quan trọng của từng thuộc tính khi lựa chọn phương thức di chuyển cho một chuyến đi ngắn (chặng đầu và chặng cuối)?"
          rawQuestion="Vui lòng cho biết mức độ quan trọng của từng thuộc tính khi lựa chọn phương thức di chuyển cho một chuyến đi ngắn (chặng đầu và chặng cuối)?"
          rows={[
            ["Thời gian", "Thời gian"],
            ["Độ trễ", "Độ trễ"],
            ["Chi phí", "Chi phí"],
            ["Tần suất", "Tần suất"],
            ["Mức độ đông đúc", "Mức độ đông đúc"],
            ["Tính di động theo yêu cầu", "Tính di động theo yêu cầu"],
            [
              <span>
                Tính đa nhiệm <br />
                (thực thi đồng thời nhiều công việc)
              </span>,
              "Tính đa nhiệm (thực thi đồng thời nhiều công việc)",
            ],
          ]}
          columns={[
            <span>
              Hoàn toàn không quan trọng <br />
              (1)
            </span>,
            <span>
              Hơi quan trọng
              <br />
              (2)
            </span>,
            <span>
              Quan trọng vừa phải
              <br />
              (3)
            </span>,
            <span>
              Rất quan trọng
              <br />
              (4)
            </span>,
            <span>
              Cực kỳ quan trọng
              <br />
              (5)
            </span>,
          ]}
          rawColumns={[1, 2, 3, 4, 5]}
          // rawColumns={[1,2]}
          firstColName="Các thuộc tính"
          colnumber={6}
        />

        {/* question 6 */}
        <ScoredAnswerQuestion
          majorIndex={majorIndex}
          mirrorIndex={isUsedMRT ? 6 : 3}
          question={
            <span>
              Thái độ và Nhận thức về hệ thống tàu đường sắt trên cao (
              <span className="font-semibold">MRT</span>)
            </span>
          }
          rawQuestion={
            "Thái độ và Nhận thức về hệ thống tàu đường sắt trên cao MRT"
          }
          answers={[
            "Tôi hài lòng với giá vé MRT hiện tại",
            "Tôi hài lòng với khoảng cách giữa các trạm dừng MRT",
            "MRT là phương tiện giao thông phù hợp với tôi",
            "Tôi tin rằng MRT hoàn toàn bằng điện sẽ cải thiện đáng kể chất lượng không khí tại Hà Nội",
            "Tôi tránh việc sử dụng MRT vì nó đông đúc",
            "Cần đầu tư thêm nguồn lực vào hệ thống MRT để nâng cao chất lượng dịch vụ",
            "Cần có sự tích hợp tốt hơn giữa các trạm dừng MRT và các trạm dịch vụ gọi xe/chia sẻ xe",
            "Hầu hết các thành viên trong gia đình tôi sử dụng MRT cho các chuyến đi chính của họ",
            "Khi có nhiều bạn bè của tôi sử dụng MRT, sẽ khiến MRT trở nên hấp dẫn hơn đối với tôi",
          ]}
        />

        {/* question 7 */}
        <ScoredAnswerQuestion
          majorIndex={majorIndex}
          mirrorIndex={isUsedMRT ? 7 : 4}
          question={<span>Ý định sử dụng xe tự lái chia sẻ</span>}
          rawQuestion={"Ý định sử dụng xe tự lái chia sẻ"}
          answers={[
            "Tôi nghĩ rằng việc sử dụng xe tự lái chia sẻ sẽ là một ý tưởng tốt.",
            "Tôi nghĩ rằng việc sử dụng xe tự lái chia sẻ sẽ cải thiện sự di chuyển hàng ngày của tôi.",
            "Tôi nghĩ rằng việc sử dụng xe tự lái chia sẻ sẽ thoải mái và tiện lợi.",

            "Những người quan trọng đối với tôi sẽ ủng hộ tôi sử dụng xe tự lái chia sẻ.",
            "Những người quan trọng đối với tôi sẽ khuyên tôi sử dụng xe tự lái chia sẻ.",
            "Những người quan trọng đối với tôi sẽ đồng ý với tôi về việc sử dụng xe tự lái chia sẻ.",

            "Tôi có thể sử dụng xe tự lái chia sẻ.",
            "Tôi nghĩ rằng sẽ dễ dàng cho tôi để sử dụng xe tự lái chia sẻ trong tương lai gần.",
            "Tôi nghĩ rằng tôi sẽ có đủ nguồn lực để sử dụng xe tự lái chia sẻ trong tương lai.",

            "Tôi tin tưởng vào công nghệ đằng sau xe tự lái chia sẻ là đáng tin cậy.",
            "Tôi tin rằng xe tự lái chia sẻ an toàn cho việc di chuyển.",
            "Tôi tin rằng các nhà cung cấp dịch vụ xe tự lái chia sẻ sẽ giải quyết các vấn đề liên quan nhanh chóng và hiệu quả.",

            "Cộng đồng của tôi đã sẵn sàng để chấp nhận xe tự lái chia sẻ như một dịch vụ di chuyển mới.",
            "Cộng đồng của tôi ủng hộ xe tự lái chia sẻ vì tiềm năng của chúng trong việc giảm phát thải carbon.",
            "Cộng đồng của tôi có những nhận xét tích cực về xe tự lái chia sẻ.",

            "Tôi sẽ cố gắng sử dụng xe tự lái chia sẻ trong tương lai.",
            "Tôi sẵn lòng sử dụng xe tự lái chia sẻ trong tương lai.",
            "Tôi có ý định sử dụng xe tự lái chia sẻ trong tương lai.",
          ]}
        />
        {/* question 8 */}
        <ScoredAnswerQuestion
          majorIndex={majorIndex}
          mirrorIndex={isUsedMRT ? 8 : 5}
          question={
            <span>
              Tác động của{" "}
              <span className="font-semibold">dịch vụ xe tự hành</span> đến việc
              sử dụng tàu đường sắt trên cao (MRT) và xe buýt nhanh (BRT)
              <br />
              Chặng đầu: Từ <span className="font-semibold">Nhà</span> đến{" "}
              <span className="font-semibold">nhà ga MRT/BRT</span>; <br />
              Chặng cuối: Từ{" "}
              <span className="font-semibold">nhà ga MRT/BRT</span> đến{" "}
              <span className="font-semibold">
                địa điểm làm việc/trường học
              </span>
            </span>
          }
          rawQuestion={
            "Tác động của dịch vụ xe tự hành đến việc sử dụng tàu đường sắt trên cao (MRT) và xe buýt nhanh (BRT)"
          }
          answers={[
            "Tôi hy vọng rằng tôi sẽ chuyển sang sử dụng MRT/BRT khi xe tự lái có sẵn để kết nối chặng đầu và chặng cuối",
            "Tôi hy xọng rằng, tôi sẽ thỉnh thoảng chuyển sang sử dụng MRT/BRT khi xe tự lái sẵn có để kết nối chặng đầu và chặng cuối trong tương lai gần",
            "Tôi hy vọng rằng tôi sẽ thường xuyên chuyển sang sử dụng MRT/BRT khi xe tự lái sẵn có để kết nối chặng đầu và chặng cuối trong tương lai gần",
            "Tôi sẽ giới thiệu với người khác chuyển sang sử dụng MRT/BRT khi xe tự lái sẵn có để kết nối chặng đầu và chặng cuối",
            "Tôi sẽ giới thiệu với người khác chuyển sang sử dụng MRT/BRT khi xe tự lái sẵn có để kết nối chặng đầu và chặng cuối trong tương lai gần",
          ]}
        />

        <StepButton
          onStepChange={setNextStep}
          currentStep={currentStep}
          flowStepsLenght={flowStepsLenght}
        />
      </Form>
    </div>
  );
};
