import "./App.css";
import React, { useState, useEffect } from "react";
import config from "./aws-exports";

import { Amplify } from "aws-amplify";
import { API } from "aws-amplify";
import { listNotes } from "./graphql/queries";
import {
  createNote as createNoteMutation,
  deleteNote as deleteNoteMutation,
} from "./graphql/mutations";

import {
  Introduce,
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  StepButton,
} from "./components";

import { Alert, Steps, Modal, Form, Button } from "antd";
import { generateUUID } from "./utils";
Amplify.configure(config);

const flowSteps = [
  {
    title: "LỜI MỞ ĐẦU",
    description: "",
  },
  {
    title: "PHẦN 1",
    description:
      "KHÁM PHÁ SỰ CHẤP NHẬN CỦA XÃ HỘI VÀ TÁC ĐỘNG VỀ HÀNH VI ĐI LẠI CỦA XE TỰ HÀNH",
  },
  {
    title: "PHẦN 2",
    description: "THÁI ĐỘ VÀ NHẬN THỨC",
  },
  {
    title: "PHẦN 3",
    description: "PHƯƠNG THỨC DI CHUYỂN HÀNG NGÀY",
  },
  {
    title: "PHẦN 4",
    description: "LỰA CHỌN PHƯƠNG THỨC DI CHUYỂN MỚI",
  },
  {
    title: "PHẦN 5",
    description: "THÔNG TIN CHUNG CỦA NGƯỜI THAM GIA KHẢO SÁT",
  },
];
function App() {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentVehicle, setCurrentVehicle] = useState();
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  const [outputData, setOutputData] = useState({});

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  useEffect(() => {}, []);
  useEffect(() => {
    console.debug("outputData:", outputData);
  }, [outputData]);

  const handleSubmit = (value, section, nextStep) => {
    setOutputData({ ...outputData, [section]: value });
    if (nextStep === flowSteps.length) {
      // console.debug("post form!");
      const formData = { ...outputData, [section]: value };
      if (Object.keys(formData).length != flowSteps.length - 1) {
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 2000);
      } else {
        // alert("post form");
        console.debug("post data: ", formData);
        postSurveys(formData);
        setShowSuccess(true);
      }
    } else if (nextStep != undefined) {
      setCurrentStep(nextStep);
    }
  };
  const handleFail = () => {};

  
  const postSurveys = (data) => {
    const myInit = {
      body: {
        id: generateUUID(),
        timestamp: Date.now(),
        value: JSON.stringify(data),
      },
    };
    API.post("api0302", "/surveys", myInit)
      .then((result) => {
        console.debug("postSurveys:", result);
      })
      .catch((err) => {
        console.error("postSurveys error:", err);
      });
  };
  const validateSection = (sectionIndex) => {
    switch (sectionIndex) {
      case 0:
        return true;
      case 1:
        return true;
      case 2:
        return true;
      case 3:
        return true;
      case 4:
        return true;
      case 5:
        return true;
    }
    return false;
  };
  const onChange = (value) => {
    if (value === 0) {
      setCurrentStep(value);
      return;
    }
    if (validateSection(currentStep)) {
      setCurrentStep(value);
    } else {
    }
  };
  if (isMobile) {
    return (
      <div className="fixed top-[40vh] left-[30vw] w-[40vw] z-50">
        <Alert
          description="Vui lòng thực hiện khảo sát trên máy tính."
          type="warning"
          showIcon
          closable={false}
          onClose={() => {
            // window.location.reload();
          }}
        />
      </div>
    );
  }
  return (
    <div className="App px-[20%] pt-[5%] pb-[10%]">
      {showError && (
        <div className="fixed top-[40vh] left-[30vw] w-[40vw] z-50">
          <Alert
            description="Vui lòng trả lời đầy đủ câu  hỏi"
            type="error"
            showIcon
            closable
            onClose={() => setShowError(false)}
          />
        </div>
      )}
      {showSuccess && (
        <div className="fixed top-[40vh] left-[30vw] w-[40vw] z-50">
          <Alert
            description="Cảm ơn anh/chị đã tham gia khảo sát"
            type="success"
            showIcon
            closable
            onClose={() => {
              window.location.reload();
            }}
          />
        </div>
      )}

      <div className="w-[15vw] fixed left-[3vw] top-[3vh]">
        <Steps
          current={currentStep}
          direction="vertical"
          onChange={onChange}
          items={flowSteps}
        />
      </div>
      <div className="">
        {/* <Button
          className="bg-green border-red-500 border-2"
          onClick={postSurveys}
        >
          TEST
        </Button> */}
        {currentStep === 0 && <Introduce />}
        <div className={currentStep === 1 ? "" : "hidden"}>
          <Section1
            title={flowSteps[currentStep]?.description}
            handleSubmit={(value, nextStep) => {
              handleSubmit(value, flowSteps[currentStep].description, nextStep);
            }}
            handleFail={handleFail}
            currentStep={currentStep}
            flowStepsLenght={flowSteps.length}
          />
        </div>
        <div className={currentStep === 2 ? "" : "hidden"}>
          <Section2
            title={flowSteps[currentStep]?.description}
            handleSubmit={(value, nextStep) =>
              handleSubmit(value, flowSteps[currentStep]?.description, nextStep)
            }
            handleFail={handleFail}
            currentStep={currentStep}
            flowStepsLenght={flowSteps.length}
          />
        </div>
        <div className={currentStep === 3 ? "" : "hidden"}>
          <Section3
            title={flowSteps[currentStep]?.description}
            handleSubmit={(value, nextStep) => {
              handleSubmit(
                value,
                flowSteps[currentStep]?.description,
                nextStep
              );
              // console.debug("vehicle: ",value?.["Phương tiện chính sử dụng Anh/Chị sử dụng hàng ngày"])
              if (
                value?.["Phương tiện chính sử dụng Anh/Chị sử dụng hàng ngày"]
              ) {
                setCurrentVehicle(
                  value?.["Phương tiện chính sử dụng Anh/Chị sử dụng hàng ngày"]
                );
              }
            }}
            handleFail={handleFail}
            currentStep={currentStep}
            flowStepsLenght={flowSteps.length}
          />
        </div>

        <div className={currentStep === 4 ? "" : "hidden"}>
          <Section4
            title={flowSteps[currentStep]?.description}
            currentVehicle={currentVehicle}
            handleSubmit={(value, nextStep) =>
              handleSubmit(value, flowSteps[currentStep]?.description, nextStep)
            }
            handleFail={handleFail}
            currentStep={currentStep}
            flowStepsLenght={flowSteps.length}
          />
        </div>
        <div className={currentStep === 5 ? "" : "hidden"}>
          <Section5
            title={flowSteps[currentStep]?.description}
            handleSubmit={(value, nextStep) =>
              handleSubmit(value, flowSteps[currentStep]?.description, nextStep)
            }
            handleFail={handleFail}
            currentStep={currentStep}
            flowStepsLenght={flowSteps.length}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
