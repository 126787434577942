import React, { useEffect, useState } from "react";
import {
  Input,
  Checkbox,
  Rate,
  Radio,
  InputNumber,
  Button,
  Dropdown,
  Space,
  Table,
  Form,
  Col,
  Row,
  Select,
} from "antd";

import { shuffle, generateUUID } from "../../utils";
import { CURRENT_DATA, SAV_DATA, AV_DATA } from "./scenario_data";

const { Option } = Select;

export const BaseQuestion = ({ majorIndex, mirrorIndex, question }) => {
  return (
    <p className={"font-normal" + majorIndex && mirrorIndex && "text-lg"}>
      {majorIndex && mirrorIndex && (
        <span className="font-semibold pr-1">
          Câu {majorIndex}.{mirrorIndex}.
        </span>
      )}

      {question}
    </p>
  );
};
export const CheckboxAnswerQuestion = ({
  majorIndex,
  mirrorIndex,
  question,
  answers,
}) => {
  return (
    <div className="text-left my-4">
      <BaseQuestion
        majorIndex={majorIndex}
        mirrorIndex={mirrorIndex}
        question={question}
      />
      <div className="flex flex-col">
        {shuffle(answers).map((answer) => (
          <Checkbox key={generateUUID()} onChange={() => {}}>
            {answer}
          </Checkbox>
        ))}
      </div>
    </div>
  );
};

export const CheckBoxGroupAnswerQuestion = ({
  majorIndex,
  mirrorIndex,
  question,
  rawQuestion = undefined,
  answers,
  rawAnswers,
  showType = "row",
}) => {
  const [answerData, setAnswerData] = useState();
  // const onchange = (e) => {
  //   setAnswerData(e.target.value);
  // };
  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
    setAnswerData(checkedValues);
    // return checkedValues;
  };

  const [options, setOptions] = useState();
  useEffect(() => {
    const options = answers.map((answer, index) => {
      return { label: answer, value: rawAnswers ? rawAnswers[index] : answer };
    });
    setOptions(options);
    console.debug("checkbox options:", options);
  }, []);
  return (
    <div className="text-left my-4">
      <BaseQuestion
        majorIndex={majorIndex}
        mirrorIndex={mirrorIndex}
        question={question}
      />
      <Form.Item
        name={rawQuestion ?? question}
        rules={[
          {
            type: "array",
            required: true,
            message: `Vui lòng trả lời "${rawQuestion ?? question}."`,
          },
        ]}
      >
        <Checkbox.Group
          options={options}
          defaultValue={undefined}
          onChange={onChange}
          value={answerData}
        />
      </Form.Item>
    </div>
  );
};
export const RadioGroupAnswerQuestion = ({
  majorIndex,
  mirrorIndex,
  question,
  rawQuestion = undefined,
  answers,
  rawAnswers,
  showType = "row",
}) => {
  const [answerData, setAnswerData] = useState();
  const onchange = (e) => {
    setAnswerData(e.target.value);
  };
  return (
    <div className="text-left my-4">
      <BaseQuestion
        majorIndex={majorIndex}
        mirrorIndex={mirrorIndex}
        question={question}
      />

      <Form.Item
        name={rawQuestion ?? question}
        rules={[
          {
            required: true,
            message: `Vui lòng trả lời "${rawQuestion ?? question}."`,
          },
        ]}
      >
        <div className="flex flex-col text-base">
          <Radio.Group size="middle" onChange={onchange} value={answerData}>
            {showType === "row" &&
              answers.map((answer, index) => (
                <Radio.Button
                  key={generateUUID()}
                  value={rawAnswers ? rawAnswers[index] : answer}
                >
                  {answer}
                </Radio.Button>
              ))}
            {showType === "column" && (
              <Space direction="vertical">
                {" "}
                {answers.map((answer, index) => (
                  <Radio
                    key={generateUUID()}
                    value={rawAnswers ? rawAnswers[index] : answer}
                  >
                    {answer}
                  </Radio>
                ))}
              </Space>
            )}
            {showType === "table" && (
              <div className="flex flex-row justify-between">
                <Space direction="vertical">
                  {" "}
                  {answers.slice(0, 5).map((answer, index) => (
                    <Radio
                      key={generateUUID()}
                      value={rawAnswers ? rawAnswers[index] : answer}
                    >
                      {answer}
                    </Radio>
                  ))}
                </Space>
                <Space direction="vertical">
                  {" "}
                  {answers.slice(5, 10).map((answer, index) => (
                    <Radio
                      key={generateUUID()}
                      value={rawAnswers ? rawAnswers[index] : answer}
                    >
                      {answer}
                    </Radio>
                  ))}
                </Space>
                <Space direction="vertical">
                  {" "}
                  {answers.slice(10, 15).map((answer, index) => (
                    <Radio key={generateUUID()} value={answer}>
                      {answer}
                    </Radio>
                  ))}
                </Space>
              </div>
            )}
          </Radio.Group>
        </div>
      </Form.Item>
    </div>
  );
};

export const ScoredAnswerQuestion = ({
  majorIndex,
  mirrorIndex,
  question,
  rawQuestion,
  answers,
}) => {
  const points = [-3, -2, -1, 0, 1, 2, 3];

  const [shuffledAnswers] = useState(shuffle(answers));
  useEffect(() => {
    // console.debug("shuffledAnswers ", shuffledAnswers);
  }, []);

  const [answersData, setAnswersData] = useState({});
  useEffect(() => {
    // console.debug("ScoredAnswerQuestion answersData: ", answersData)
  }, [answersData]);
  const renderTable = () => {
    return (
      <>
        <table className="table-fixed border-collapse w-full text-center">
          <thead>
            <tr className="border-t-2 border-l-2 border-r-2 border-solid border-gray-500">
              <th className="border-[1px] border-dashed border-gray-500">
                Hoàn toàn không đồng ý
              </th>
              <th className="border-[1px] border-dashed border-gray-500">
                Rất không đồng ý
              </th>
              <th className="border-[1px] border-dashed border-gray-500">
                Không đồng ý
              </th>
              <th className="border-t-2 border-t-solid border-gray-500">
                Trung lập
              </th>
              <th className="border-[1px] border-dashed border-gray-500">
                Đồng ý
              </th>
              <th className="border-[1px] border-dashed border-gray-500">
                Rất đồng ý
              </th>
              <th className="border-[1px] border-dashed border-gray-500">
                Hoàn toàn đồng ý
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b-2 border-l-2 border-r-2 border-solid border-gray-500">
              <td className="border-[1px] border-dashed border-gray-500">-3</td>
              <td className="border-[1px] border-dashed border-gray-500">-2</td>
              <td className="border-[1px] border-dashed border-gray-500">-1</td>
              <td className="border-[1px] border-dashed border-gray-500">0</td>
              <td className="border-[1px] border-dashed border-gray-500">1</td>
              <td className="border-[1px] border-dashed border-gray-500">2</td>
              <td className="border-[1px] border-dashed border-gray-500">3</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };
  return (
    <div className="text-left my-4">
      <BaseQuestion
        majorIndex={majorIndex}
        mirrorIndex={mirrorIndex}
        question={question}
      />
      <div className="my-3">
        <p>
          Anh/Chị hãy cho biết ý kiến của mình về các nhận định sau, bằng cách
          khoanh tròn trước số tương ứng với thang điểm trong bảng dưới đây.
        </p>
        {renderTable()}
      </div>

      <div className="flex flex-col">
        {shuffledAnswers.map((answer) => (
          <Form.Item
            key={generateUUID()}
            name={`${rawQuestion}.${answer}`}
            rules={[
              {
                required: true,
                message: `Vui lòng trả lời "${answer}."`,
              },
            ]}
          >
            <div className="grid grid-cols-12 my-1">
              <p className="col-span-8">{answer}</p>
              <div className="col-span-4">
                <Radio.Group
                  size="middle"
                  onChange={(e) => {
                    // console.debug(`${answer} ${e.target.value}`);
                    setAnswersData({
                      ...answersData,
                      [answer]: e.target.value,
                    });
                  }}
                  value={answersData?.[answer]}
                >
                  {points.map((point) => (
                    <Radio.Button key={generateUUID()} value={point}>
                      {point}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </div>
            </div>
          </Form.Item>
        ))}
      </div>
    </div>
  );
};

export const YesOrNoQuestion = ({
  majorIndex,
  mirrorIndex,
  question,
  rawQuestion,
  yesAnswer = "Đã từng",
  noAnswer = "Chưa từng",
}) => {
  const [answersData, setAnswersData] = useState();
  const onChange = (e) => {
    // console.log('radio checked', e.target.value);
    // setAnswersData(e.target.value);
  };
  return (
    <div className="text-left my-4">
      <BaseQuestion
        majorIndex={majorIndex}
        mirrorIndex={mirrorIndex}
        question={question}
      />
      <Form.Item
        name={rawQuestion}
        rules={[
          {
            required: true,
            message: `Vui lòng trả lời "${rawQuestion}."`,
          },
        ]}
      >
        <div className="">
          <Radio.Group
            size="middle"
            className="flex justify-around"
            onChange={onChange}
          >
            <Radio value={yesAnswer}>{yesAnswer}</Radio>
            <Radio value={noAnswer}>{noAnswer}</Radio>
          </Radio.Group>
        </div>
      </Form.Item>
    </div>
  );
};

export const InputNumberQuestion = ({
  majorIndex,
  mirrorIndex,
  question,
  rawQuestion,
  unit,
  min = 0,
  max = 100,
}) => {
  const [answersData, setAnswersData] = useState();
  const onChange = (value) => {
    console.log("changed", value);
    setAnswersData(value);
  };
  return (
    <div className="text-left my-4">
      <BaseQuestion
        majorIndex={majorIndex}
        mirrorIndex={mirrorIndex}
        question={question}
      />
      <Form.Item
        name={rawQuestion}
        rules={[
          {
            required: true,
            // type: "number",

            message: `Vui lòng trả lời "${rawQuestion}".`,
          },
          {
            pattern: new RegExp(/^[0-9]+$/),
            message: `Vui lòng nhập giá trị hợp lệ.`,
          },
        ]}
      >
        <div className="flex flex-row">
          {/* <InputNumber
            min={min}
            max={max}
            defaultValue={undefined}
            onChange={onChange}
            value={answersData}
            className="w-1/3"
          /> */}
          <Input className="w-1/3" />
          <p className="ml-1 my-auto">({unit})</p>
        </div>
      </Form.Item>
    </div>
  );
};

export const InputQuestion = ({
  majorIndex,
  mirrorIndex,
  question,
  placeholder,
  rawQuestion,
}) => {
  const onChange = (e) => {};
  return (
    <div className="text-left my-4">
      <BaseQuestion
        majorIndex={majorIndex}
        mirrorIndex={mirrorIndex}
        question={question}
      />
      <Form.Item
        // label="Name"
        name={rawQuestion}
        rules={[
          {
            required: true,
            message: `Vui lòng trả lời "${rawQuestion}".`,
          },
        ]}
      >
        <Input className="" placeholder={placeholder} onChange={onChange} />
      </Form.Item>
    </div>
  );
};

export const TableQuestionBackup = ({
  majorIndex,
  mirrorIndex,
  question,
  columns,
  rawColumns,
  rows,
  firstColName = "",
  rawQuestion,
}) => {
  const [selected, setSelected] = useState({});
  const [data, setData] = useState({});
  useEffect(() => {}, [selected]);

  const onRadioChange = (e) => {
    let value = e.currentTarget.value;
    let name = e.currentTarget.name;
    // let name = rawColumns[value];

    console.debug("onRadioChange ", name, rawColumns[value]);
    setSelected({ ...selected, [name]: value });
    setData({ ...data, [name]: rawColumns[value] });
  };
  let renderColumns = [];

  renderColumns.push({
    title: <p className="text-left">{firstColName}</p>,
    dataIndex: "name",
  });
  columns.forEach((option, colIndex) => {
    // console.debug("columns ",option, colIndex)
    // if (colIndex ===0){

    // }
    renderColumns.push({
      title: <p className="text-center">{option}</p>,
      key: option,
      render: (col) => {
        return (
          <div className="flex justify-center">
            <input
              key={generateUUID()}
              type="radio"
              checked={selected[col.name] == colIndex}
              onChange={onRadioChange}
              name={col.name}
              value={colIndex}
            />
            {/* {col.name} {colIndex} */}
          </div>
        );
      },
    });
  });
  return (
    <div className="text-left my-4">
      <BaseQuestion
        majorIndex={majorIndex}
        mirrorIndex={mirrorIndex}
        question={question}
      />

      <Table
        columns={renderColumns}
        dataSource={rows.map((row) => {
          return {
            key: generateUUID(),
            name: row,
          };
        })}
        size="middle"
        bordered
        pagination={false}
      />
    </div>
  );
};

export const TableQuestion = ({
  majorIndex,
  mirrorIndex,
  question,
  columns,
  rawColumns,
  rows,
  // rawRows,
  firstColName = "",
  rawQuestion,
  colnumber = 6,
}) => {
  const [selected, setSelected] = useState({});
  const [shuffledRows] = useState(shuffle(rows));

  
  return (
    <div className="text-left my-4">
      <BaseQuestion
        majorIndex={majorIndex}
        mirrorIndex={mirrorIndex}
        question={question}
      />

      {colnumber === 6 ?<div className={"grid grid-cols-6 gap-1"}>
        <div className={`font-semibold text-left`}>{firstColName}</div>
        {columns.map((column) => {
          return (
            <div className={`font-semibold text-center`} key={generateUUID()}>
              {column}
            </div>
          );
        })}
      </div>:<div className={"grid grid-cols-12 gap-1"}>
        <div className={`font-semibold text-left`}>{firstColName}</div>
        {columns.map((column) => {
          return (
            <div className={`font-semibold text-center`} key={generateUUID()}>
              {column}
            </div>
          );
        })}
      </div>}
      

      {shuffledRows.map((row) => {
        return (
          <Form.Item
            key={generateUUID()}
            name={`${rawQuestion}.${row[1]}`}
            rules={[
              {
                required: true,
                message: `Vui lòng trả lời "${row[1]}".`,
              },
            ]}
          >
            {colnumber === 6 ?<Radio.Group
              size="middle"
              onChange={(e) => {
                setSelected({
                  ...selected,
                  [row[1]]: e.target.value,
                });
              }}
              value={selected?.[row[1]]}
              className={"grid grid-cols-6 gap-1"}
            >
              <p className={`text-base text-left`}>{row[0]}</p>

              {rawColumns.map((column) => {
                return (
                  <div
                    className={`font-semibold text-center`}
                    key={generateUUID()}
                  >
                    <Radio value={column}></Radio>
                  </div>
                );
              })}
            </Radio.Group>:<Radio.Group
              size="middle"
              onChange={(e) => {
                setSelected({
                  ...selected,
                  [row[1]]: e.target.value,
                });
              }}
              value={selected?.[row[1]]}
              className={"grid grid-cols-12 gap-1"}
            >
              <p className={`text-base text-left`}>{row[0]}</p>

              {rawColumns.map((column) => {
                return (
                  <div
                    className={`font-semibold text-center`}
                    key={generateUUID()}
                  >
                    <Radio value={column}></Radio>
                  </div>
                );
              })}
            </Radio.Group>}
            
          </Form.Item>
        );
      })}
    </div>
  );
};

export const ScenarioQuestion = ({ currentVehicle, scenceIndex, isBefore }) => {
  return (
    <div className="my-6">
      <p className="text-lg font-semibold">Kịch bản: {scenceIndex + 1}</p>
      <table className="table-fixed border-collapse w-full text-center">
        <thead>
          <tr className="border-t-2 border-l-2 border-r-2 border-solid border-gray-500">
            <td
              colSpan="2"
              className="border-[1px] border-dashed border-gray-500 text-left"
            ></td>
            <td className="border-[1px] border-dashed border-gray-500">
              {/* Phương thức hiện tại */}
              {currentVehicle ?? "Phương thức hiện tại"}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              <p>
                Dịch vụ gọi xe tự hành{" "}
                <span className="font-semibold underline">Chia sẻ</span> chuyến
                đi với người khác
              </p>
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              <p>
                Dịch vụ gọi xe tự hành{" "}
                <span className="font-semibold underline">Không chia sẻ</span>{" "}
                chuyến đi với người khác
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="border-l-2 border-r-2 border-solid border-gray-500">
            <td
              rowSpan="3"
              className="border-[1px] border-dashed border-gray-500 text-left"
            >
              Tổng thời gian (phút)
            </td>
            <td className="border-[1px] border-dashed border-gray-500 text-left">
              Thời gian di chuyển
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {currentVehicle != undefined && CURRENT_DATA[currentVehicle][0]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {SAV_DATA[scenceIndex][0]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {AV_DATA[scenceIndex][0]}
            </td>
          </tr>
          <tr className="border-l-2 border-r-2 border-solid border-gray-500">
            <td className="border-[1px] border-dashed border-gray-500 text-left">
              Thời gian chờ
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {currentVehicle != undefined && CURRENT_DATA[currentVehicle][1]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {SAV_DATA[scenceIndex][1]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {AV_DATA[scenceIndex][1]}
            </td>
          </tr>
          <tr className="border-l-2 border-r-2 border-solid border-gray-500">
            <td className="border-[1px] border-dashed border-gray-500 text-left">
              Thời gian đỗ xe
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {currentVehicle != undefined && CURRENT_DATA[currentVehicle][2]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {SAV_DATA[scenceIndex][2]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {AV_DATA[scenceIndex][2]}
            </td>
          </tr>
          <tr className="border-l-2 border-r-2 border-solid border-gray-500">
            <td
              colSpan="2"
              className="border-[1px] border-dashed border-gray-500 text-left"
            >
              Độ trễ của xe (phút)
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {currentVehicle != undefined && CURRENT_DATA[currentVehicle][3]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {SAV_DATA[scenceIndex][3]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {AV_DATA[scenceIndex][3]}
            </td>
          </tr>
          <tr className="border-l-2 border-r-2 border-solid border-gray-500">
            <td rowSpan="2" className="text-left">
              Tổng chi phí (VND)
            </td>
            <td className="border-[1px] border-dashed border-gray-500 text-left">
              Giá vé
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {currentVehicle != undefined && CURRENT_DATA[currentVehicle][4]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {SAV_DATA[scenceIndex][4]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {AV_DATA[scenceIndex][4]}
            </td>
          </tr>
          <tr className="border-l-2 border-r-2 border-solid border-gray-500">
            <td className="border-[1px] border-dashed border-gray-500 text-left">
              Chi phí đỗ xe
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {currentVehicle != undefined && CURRENT_DATA[currentVehicle][5]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {SAV_DATA[scenceIndex][5]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {AV_DATA[scenceIndex][5]}
            </td>
          </tr>
          <tr className="border-l-2 border-r-2 border-solid border-gray-500">
            <td
              colSpan="2"
              className="border-[1px] border-dashed border-gray-500 text-left"
            >
              Tần suất, số chuyến/giờ
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {currentVehicle != undefined && CURRENT_DATA[currentVehicle][6]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {SAV_DATA[scenceIndex][6]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {AV_DATA[scenceIndex][6]}
            </td>
          </tr>
          <tr className="border-l-2 border-r-2 border-solid border-gray-500">
            <td colSpan="2" className="text-left">
              Mức độ đông đúc
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {currentVehicle != undefined && CURRENT_DATA[currentVehicle][7]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {SAV_DATA[scenceIndex][7]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {AV_DATA[scenceIndex][7]}
            </td>
          </tr>
          <tr className="border-l-2 border-r-2 border-solid border-gray-500">
            <td
              colSpan="2"
              className="border-[1px] border-dashed border-gray-500 text-left"
            >
              Loại dịch vụ, tính di động
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {currentVehicle != undefined && CURRENT_DATA[currentVehicle][8]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {SAV_DATA[scenceIndex][8]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {AV_DATA[scenceIndex][8]}
            </td>
          </tr>
          <tr className="border-b-2 border-l-2 border-r-2 border-solid border-gray-500">
            <td
              colSpan="2"
              className="border-[1px] border-dashed border-gray-500 text-left"
            >
              Tính đa nhiệm (thực thi đồng thời các công việc khi di chuyển)
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {currentVehicle != undefined && CURRENT_DATA[currentVehicle][9]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {SAV_DATA[scenceIndex][9]}
            </td>
            <td className="border-[1px] border-dashed border-gray-500">
              {AV_DATA[scenceIndex][9]}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="pl-4">
        <RadioGroupAnswerQuestion
          majorIndex={undefined}
          mirrorIndex={undefined}
          question={<span className="font-semibold">Lựa chọn</span>}
          rawQuestion={`${isBefore ? "Trước" : "Sau"}.Kịch bản: ${
            scenceIndex + 1
          }`}
          showType="column"
          answers={[
            // "Phương thức hiện tại",
            currentVehicle ?? "Phương thức hiện tại",
            <p>
              Dịch vụ gọi xe tự hành{" "}
              <span className="font-semibold underline">Chia sẻ</span> chuyến đi
              với người khác
            </p>,
            <p>
              Dịch vụ gọi xe tự hành{" "}
              <span className="font-semibold underline">Không chia sẻ</span>{" "}
              chuyến đi với người khác
            </p>,
          ]}
          rawAnswers={[
            "Phương thức hiện tại",
            "Dịch vụ gọi xe tự hành Chia sẻ chuyến đi với người khác",
            "Dịch vụ gọi xe tự hành Không chia sẻ chuyến đi với người khác",
          ]}
        />
      </div>
    </div>
  );
};
