import { useEffect, useState } from "react";
import {
  Title,
  BaseQuestion,
  YesOrNoQuestion,
  InputNumberQuestion,
  InputQuestion,
  ScoredAnswerQuestion,
  CheckboxAnswerQuestion,
  RadioGroupAnswerQuestion,
  ScenarioQuestion,
} from "./base";
import { generateUUID } from "../utils";
import { Form } from "antd";
import { StepButton } from "./step-button";
export const Section4 = ({
  title,
  currentVehicle,
  handleSubmit,
  handleFail,
  currentStep,
  flowStepsLenght,
}) => {
  const majorIndex = 4;
  // const title = "khảo sát sở thích tuyên bố";
  const [before, setBefore] = useState([]);
  const [after, setAfter] = useState([]);
  const [nextStep, setNextStep] = useState();

  const randomScenario = () => {
    return Math.floor(Math.random() * 35);
  };

  useEffect(() => {
    while (before.length < 3) {
      const randomIndex = randomScenario();
      if (before.indexOf(randomIndex) === -1) {
        before.push(randomIndex);
      }
    }
    while (after.length < 3) {
      const randomIndex = randomScenario();
      if (after.indexOf(randomIndex) === -1) {
        after.push(randomIndex);
      }
    }
  }, []);
  const renderTable = () => {
    return (
      <>
        <table className="table-fixed border-collapse w-full text-center">
          <thead>
            <tr className="border-t-2 border-l-2 border-r-2 border-solid border-gray-500">
              <th className="border-[1px] border-dashed border-gray-500">
                <p className="font-normal">
                  Phương thức hiện tại Anh/Chị đang sử dụng
                </p>
              </th>
              <th className="border-[1px] border-dashed border-gray-500">
                <p className="font-normal">
                  Phương thức 1: Dịch vụ gọi xe tự hành,{" "}
                  <span className="font-semibold">chia sẻ</span> chuyến đi với
                  nhiều người khác
                </p>
              </th>
              <th className="border-[1px] border-dashed border-gray-500">
                <p className="font-normal">
                  Phương thức 2: Dịch vụ gọi xe tự hành, chỉ một mình,{" "}
                  <span className="font-semibold">không chia sẻ</span> chuyến đi
                  với người khác
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b-2 border-l-2 border-r-2 border-solid border-gray-500">
              <td className="border-x-[1px] border-dashed border-gray-500">
                <p className="font-semibold">{currentVehicle}</p>
              </td>
              <td className="border-x-[1px] border-dashed border-gray-500">
                <img
                  src={require("../assets/images/sharedService.jpg")}
                  width="80%"
                  height={"80%"}
                  className="rounded-lg mx-auto my-1"
                ></img>
              </td>
              <td className="border-x-[1px] border-dashed border-gray-500">
                <img
                  src={require("../assets/images/notSharedService.jpg")}
                  width="80%"
                  height={"80%"}
                  className="rounded-lg mx-auto my-1"
                ></img>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };
  const renderDescription = () => {
    return (
      <div>
        <div>
          <p>
            Xe tự hành sẽ được đưa vào sử dụng tại Hà Nội để kết nối từ{" "}
            <span className="font-semibold underline">Nhà ở/Nơi làm việc</span>{" "}
            của Anh/Chị đến ga tàu đường sắt trên cao (MRT)/xe buýt nhanh (BRT).
            Giả sử rằng
            <span className="font-semibold underline">
              Nhà ở/Nơi làm việc
            </span>{" "}
            của Anh/Chị nằm trong khu vực MRT/BRT.{" "}
            <span className="font-semibold underline">
              Hai phương thức vận chuyển mới
            </span>{" "}
            sẽ có sẵn:
          </p>
          {renderTable()}
        </div>
        <div className="my-4">
          <p>
            Xem xét <span className="font-semibold">hai phương thức mới</span>{" "}
            này và <span className="font-semibold">phương thức hiện tại</span>,
            Anh/Chị sẽ Lựa chọn phương thức di chuyển nào để di chuyển hàng ngày
            từ{" "}
            <span className="font-semibold underline">
              Nhà ở/Nơi làm việc, trường học
            </span>{" "}
            đến trạm MRT/BRT?{" "}
            <span className="italic">
              (Giả sử khoảng cách từ Nhà ở/Nơi làm việc, trường học của Anh/Chị
              đến trạm MRT/BRT là 2 km)
            </span>
          </p>
          <img
            src={require("../assets/images/simulationMap.jpg")}
            width="100%"
            height={"100%"}
            className="rounded-lg"
          ></img>
        </div>
        <p className="font-semibold">
          ANH/CHỊ VUI LÒNG LỰA CHỌN VÀO 1 Ô TRONG TRONG CÁC KỊCH BẢN SAU ĐÂY
        </p>
      </div>
    );
  };

  return (
    <div className="text-left">
      <Title index={majorIndex} content={title} />

      {renderDescription()}
      <Form
        onFinish={(value) => handleSubmit(value, nextStep)}
        onFinishFailed={handleFail}
      >
        {before.map((scenceIndex) => (
          <ScenarioQuestion
            key={generateUUID()}
            currentVehicle={currentVehicle}
            scenceIndex={scenceIndex}
            isBefore={true}
          />
        ))}
        <p className="font-semibold text-xl text-center">
          Kính mời Anh/Chị xem video giới thiệu về Xe tự hành cấp độ 4 đầu tiên
          tại Việt Nam và tiếp tục trả lời các câu hỏi phía dưới!
        </p>
        <div className="video-responsive">
          <iframe
            className="w-full aspect-video"
            src="https://www.youtube.com/embed/7iIKUxJfwr4"
            title="Xe tự hành Phenikaa cấp độ 4 đầu tiên tại Việt Nam"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            // allowfullscreen
          ></iframe>
        </div>

        {after.map((scenceIndex) => (
          <ScenarioQuestion
            key={generateUUID()}
            currentVehicle={currentVehicle}
            scenceIndex={scenceIndex}
            isBefore={false}
          />
        ))}
        <StepButton
          onStepChange={setNextStep}
          currentStep={currentStep}
          flowStepsLenght={flowStepsLenght}
        />
      </Form>
    </div>
  );
};
