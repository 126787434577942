import React, { useState, useEffect } from "react";
import config from "../aws-exports";

import { Amplify } from "aws-amplify";
import { API } from "aws-amplify";

import { Alert, Steps, Modal, Form, Button } from "antd";
import { generateUUID } from "../utils";
Amplify.configure(config);

function Surveys() {
  const [total, setTotal] = useState();
  const [surveys, setSurveys] = useState([]);
  const [keys, setKeys] = useState([]);
  const [nextKey, setNextKey] = useState();
  useEffect(() => {
    fetchSurveys();
    countSurveys();
  }, []);

  const fetchSurveys = async (lastkey = null, isNext = true) => {
    if (lastkey) {
      try {
        const result = await API.get("api0302", `/surveys/${lastkey}`, {});
        console.debug(`fetchSurveys ${lastkey}:`, result);

        setSurveys(result.data);
        if (isNext) {
          keys.push(lastkey);
        } else {
          keys.pop();
        }
        setNextKey(result.lastEvaluatedKey?.id);
      } catch (err) {
        console.error(`fetchSurveys ${lastkey} error:`, err);
      }
    } else {
      try {
        const result = await API.get("api0302", `/surveys`, {});
        console.debug(`fetchSurveys ${lastkey}:`, result);
        setSurveys(result.data);
        setNextKey(result.lastEvaluatedKey.id);
      } catch (err) {
        console.error(`fetchSurveys ${lastkey} error:`, err);
      }
    }
  };

  const countSurveys = () => {
    API.put("api0302", `/surveys`)
      .then((result) => {
        console.debug(`countSurveys :`, result);
        setTotal(result);
      })
      .catch((err) => {
        console.error(`countSurveys error:`, err);
      });
  };

  const renderTable = () => {
    return (
      <div className="h-screen">
        <table className=" border-collapse w-full h-1/2 overflow-scroll">
          <thead>
            <tr>
              {/* <th className="border-t-2 border-solid border-gray-500">ID</th> */}
              <th className="border-t-2 border-solid border-gray-500">Time</th>
              <th className="border-t-2 border-solid border-gray-500">Name</th>
              <th className="border-t-2 border-solid border-gray-500"></th>
            </tr>
          </thead>
          <tbody>
            {surveys?.map((survey) => {
              var months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ];
              var utcTime = new Date(survey.timestamp);
              var year = utcTime.getFullYear();
              var month = months[utcTime.getMonth()];
              var date = utcTime.getDate();
              var hour = utcTime.getHours();
              var min = utcTime.getMinutes();
              var sec = utcTime.getSeconds();
              var time =
                date +
                " " +
                month +
                " " +
                year +
                " " +
                hour +
                ":" +
                min +
                ":" +
                sec;
              return (
                <tr key={survey.id}>
                  {/* <td className="border-y-[1px] border-dashed border-gray-500">
                    {survey.id}
                  </td> */}
                  <td className="border-y-[1px] border-dashed border-gray-500">
                    {time}
                  </td>
                  <td className="border-y-[1px] border-dashed border-gray-500 truncate">
                    {
                      JSON.parse(survey.value)[
                        "THÔNG TIN CHUNG CỦA NGƯỜI THAM GIA KHẢO SÁT"
                      ]["Họ và tên"]
                    }
                  </td>
                  <td className="border-y-[1px] border-dashed border-gray-500">
                    200,000
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="w-full flex flex-row justify-between mt-4">
          <Button
            className="bg-blue-500 rounded-md py-0 px-6 disabled:bg-blue-300"
            disabled={keys.length == 0}
            onClick={() => fetchSurveys(keys[keys.length - 1], false)}
            type="primary"
            htmlType="submit"
          >
            Previos
          </Button>
          <Button
            className="bg-blue-500 rounded-md py-0 px-6 disabled:bg-blue-300"
            disabled={!nextKey}
            onClick={() => fetchSurveys(nextKey, true)}
            type="primary"
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </div>
    );
  };
  return (
    <div className="App px-[20%] pt-[5%] pb-[10%]">
      <p className="font-semibold text-xl mb-4">Surveys reulst table</p>
      <div className="flex flex-row justify-between">
        <p>Total:{total}</p>
        <button>Download</button>
      </div>
      {renderTable()}
    </div>
  );
}

export default Surveys;
